<template>
<div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#005f32"
      spinner="bar-fade-scale"
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
        <v-flex xs12>
            hii
        </v-flex>
    </v-layout>
</div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
        showsnackbar:false,
      appLoading: false,
      policyList:[],
        };
  },
  mounted() {
    this.getData();
  },
    methods: {
         getData() {
      this.appLoading = true;
      axios({
        url: "/policy/getlist",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.policyList = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    },
  };
</script>